import React, { useState, useEffect } from 'react';
import ReactGA from "react-ga4";
ReactGA.initialize("G-WZK9JXJCS5");

function Header() {
    const [scrolled, setScrolled] = useState(false);
    const select = (el, all = false) => {
        el = el.trim()
        if (all) {
        return [...document.querySelectorAll(el)]
        } else {
        return document.querySelector(el)
        }
    }
    /**
     * Scrolls to an element with header offset
     */
    const scrollto = (el) => {
        let header = select('#header')
        let offset = header.offsetHeight
        let elementPos = select(el).offsetTop
        window.scrollTo({
            top: elementPos - offset,
            behavior: 'smooth'
        })
    }
    const mobile_nav_toggle = (event) => {
        select('#navbar').classList.toggle('navbar-mobile')
        select(".mobile-nav-toggle").classList.toggle('bi-list')
        select(".mobile-nav-toggle").classList.toggle('bi-x')
    }
    const menuClicked = (hash, event) => {
        event.preventDefault();
        ReactGA.send({ hitType: "pageview", page: "/"+hash, title: hash.replace('#','') });
        let navbarlinks = select('#navbar .scrollto', true);
        navbarlinks.forEach(navbarlink => {
            if (!navbarlink.hash) return
            
            if (navbarlink.hash === hash) {
                navbarlink.classList.add('active')
            } else {
                navbarlink.classList.remove('active')
            }
        })
        if (select(hash)) {
            let navbar = select('#navbar')
            if (navbar.classList.contains('navbar-mobile')) {
                navbar.classList.remove('navbar-mobile')
                let navbarToggle = select('.mobile-nav-toggle')
                navbarToggle.classList.toggle('bi-list')
                navbarToggle.classList.toggle('bi-x')
            }
            scrollto(hash)
        }
    }
    useEffect(() => {
        const handleScroll = () => {
            const isScrolled = window.scrollY > 100;
            if(isScrolled){
                setScrolled(isScrolled);
            }else{
                setScrolled(false);
            }
        };

        let navbarlinks = select('#navbar .scrollto', true)
        const navbarlinksActive = () => {
            let position = window.scrollY + 200
            navbarlinks.forEach(navbarlink => {
            if (!navbarlink.hash) return
            let section = select(navbarlink.hash)
            if (!section) return
            if (position >= section.offsetTop && position <= (section.offsetTop + section.offsetHeight)) {
                navbarlink.classList.add('active')
            } else {
                navbarlink.classList.remove('active')
            }
            })
        }

        window.addEventListener('scroll', handleScroll);
        window.addEventListener('scroll', navbarlinksActive);

        // Clean up the event listener on unmount
        return () => {
        window.removeEventListener('scroll', handleScroll);
        window.removeEventListener('scroll', navbarlinksActive);
        };
    }, []);

    const existingClassName = 'fixed-top d-flex align-items-center header-transparent'; // Example of existing class names

    const headerClassName = `${existingClassName} ${scrolled ? 'header-scrolled' : ''}`;

    return (
        <header id="header" className={headerClassName}>
            <div className="container d-flex align-items-center justify-content-between">

                <div className="logo">
                    <a href="index.html"><img src="assets/img/fireash_logo_for_white_bg.png" alt="" className="img-fluid" /></a>
                </div>

                <nav id="navbar" className="navbar">
                    <ul>
                        <li><a className="nav-link scrollto active" href="/#hero" onClick={(event) => menuClicked("#hero",event)}>Home</a></li>
                        <li><a className="nav-link scrollto" href="/#howitworks" onClick={(event) => menuClicked("#howitworks",event)}>How It Works</a></li>
                        <li><a className="nav-link scrollto" href="/#services" onClick={(event) => menuClicked("#services",event)}>Services</a></li>
                        <li><a className="nav-link scrollto" href="/#pricing" onClick={(event) => menuClicked("#pricing",event)}>Pricing</a></li>
                        <li><a className="nav-link scrollto" href="/#contact" onClick={(event) => menuClicked("#contact",event)}>Contact</a></li>
                        <li><a href="https://app.fireash.com/login" className="sign-in-btn">Login</a></li>
                        <li><a href="https://app.fireash.com/register" className="btn-get-started">Get started for free</a></li>
                    </ul>
                    <i className="bi bi-list mobile-nav-toggle" onClick={(event) => mobile_nav_toggle(event)}></i>
                </nav>

            </div>
        </header>
    );
}

export default Header;