import './App.css';
import Header from './partials/header';
import Footer from './partials/footer';
import Hero from './partials/hero';
import Howitworks from './partials/howitworks';
import Services from './partials/services';
import Pricing from './partials/pricing';
import Contact from './partials/contact';

function App() {
  return (
    <>
    <Header />
     <Hero />

    <main id="main">
        <Howitworks />
        <Services />
        <Pricing />
        <Contact />
    </main>
    <Footer />
    </>
  );
}

export default App;
