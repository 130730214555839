function Footer () {
    return (
        <footer id="footer">
            <div className="container">
                <h3>Fireash</h3>
                <div className="copyright">
                    &copy; Copyright <strong><span>Fireash</span></strong>. All Rights Reserved
                </div>
            </div>
        </footer>
    );
}

export default Footer;