function Services() { 
    return (
        <section id="services" className="services">
            <div className="container">

                <div className="section-title" data-aos="zoom-out">
                    <h2>Services</h2>
                    <p>What we do offer</p>
                </div>

                <div className="row">
                    <div className="col-lg-4 col-md-6">
                        <div className="icon-box" data-aos="zoom-in-left">
                            <div className="icon"><i className="bi bi-file-earmark-spreadsheet" style={{color: "#0ba817"}}></i></div>
                            <h4 className="title"><a href="javascript" onClick={(e) => {e.preventDefault();}}>Seamless Excel Data Extraction</a></h4>
                            <p className="description">Fireash's ETL platform seamlessly integrates with Excel spreadsheets, allowing you to extract data from multiple sheets, workbooks, and ranges. We support various Excel file formats, including .xls and .xlsx, ensuring compatibility with your existing data sources. Say goodbye to manual data extraction and enjoy automated processes.</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 mt-5 mt-md-0">
                        <div className="icon-box" data-aos="zoom-in-left" data-aos-delay="100">
                            <div className="icon"><i className="bi bi-filetype-xml" style={{color: "#e9bf06"}}></i></div>
                            <h4 className="title"><a href="javascript" onClick={(e) => {e.preventDefault();}}>Advanced XML Transformation</a></h4>
                            <p className="description">Working with XML data requires specialized handling, and Fireash's ETL tools provide robust XML transformation capabilities. You can parse, validate, and transform XML documents with ease. Extract specific elements, apply custom transformations, and generate XML outputs tailored to your requirements.</p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 mt-5 mt-lg-0 ">
                        <div className="icon-box" data-aos="zoom-in-left" data-aos-delay="200">
                            <div className="icon"><i className="bi bi-cloud" style={{color: "#3fcdc7"}} ></i></div>
                            <h4 className="title"><a href="javascript" onClick={(e) => {e.preventDefault();}}>Flexible Data Loading</a></h4>
                            <p className="description">Once your Excel and XML data is transformed, Fireash's ETL solution offers flexible loading options to your desired destinations. We are offering REST Api, MySql and AWS S3 integration. We ensure seamless integration and efficient data loading.</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 mt-5">
                        <div className="icon-box" data-aos="zoom-in-left" data-aos-delay="300">
                            <div className="icon"><i className="bi bi-speedometer" style={{color: "#41cf2e"}} ></i></div>
                            <h4 className="title"><a href="javascript" onClick={(e) => {e.preventDefault();}}>Scalability and Performance</a></h4>
                            <p className="description">We understand the challenges of handling large volumes of Excel and XML data. Fireash's ETL solution is designed to scale, delivering optimal performance even with massive datasets. With parallel processing and optimized workflows, we ignite your Excel and XML data's potential at lightning-fast speeds.</p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 mt-5">
                        <div className="icon-box" data-aos="zoom-in-left" data-aos-delay="400">
                            <div className="icon"><i className="bi bi-shield-check" style={{color: "#d6ff22"}} ></i></div>
                            <h4 className="title"><a href="javascript" onClick={(e) => {e.preventDefault();}}>Secure and Reliable</a></h4>
                            <p className="description">Data security is paramount, and Fireash prioritizes the protection of your data during the loading process. Our solution employs industry-standard security measures and encryption protocols to ensure the confidentiality and integrity of your data. You can trust Fireash to handle your data with the utmost care and maintain its security throughout the loading process.</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 mt-5">
                        <div className="icon-box" data-aos="zoom-in-left" data-aos-delay="500">
                            <div className="icon"><i className="bi bi-clock" style={{color: "#4680ff"}}></i></div>
                            <h4 className="title"><a href="javascript" onClick={(e) => {e.preventDefault();}}>Data Quality and Governance</a></h4>
                            <p className="description">Maintaining data integrity and ensuring data quality is crucial. Fireash's ETL platform includes built-in data quality checks and validation mechanisms for Excel and XML data. You can identify and resolve any data issues, ensuring accurate insights and compliance with standards.</p>
                        </div>
                    </div>
                </div>

            </div>
        </section>
    )
}

export default Services;