function Pricing() { 
    return (
        <section id="pricing" className="pricing">
            <div className="container">

                <div className="section-title" data-aos="zoom-out">
                    <h2>Pricing</h2>
                    <p>Transparent Pricing: No Hidden Cost</p>
                </div>
                <div className="row">
                    <div className="table-responsive">
                        <table className="table table-bordered pricing-table" data-aos="zoom-in" data-aos-delay="300">
                            <thead>
                                <tr>
                                    <th className="pricing-table-subHeading">Plan</th>
                                    <th className="pricing-heading">Free Forever</th>
                                    <th className="pricing-heading">Standard</th>
                                    <th className="pricing-heading">Business</th>
                                    <th className="pricing-heading box">Premium <span className="advanced">Popular</span></th>
                                </tr>
                                <tr>
                                    <th className="pricing-table-subHeading">Price</th>
                                    <td>
                                        <h4><sup>$</sup>0<span> / month</span></h4>
                                        <div className="btn-wrap">
                                            <a href="https://app.firash.com/register" className="btn-buy">Start Free</a>
                                        </div>
                                    </td>
                                    <td>
                                        <h4><sup>$</sup>15<span> / month</span></h4>
                                        <div className="btn-wrap">
                                            <a href="https://app.firash.com/register" className="btn-buy">Start Free</a>
                                        </div>
                                    </td>
                                    <td>
                                        <h4><sup>$</sup>60<span> / month</span></h4>
                                        <div className="btn-wrap">
                                            <a href="https://app.firash.com/register" className="btn-buy">Start Free</a>
                                        </div>
                                    </td>
                                    <td>
                                        <h4><sup>$</sup>150<span> / month</span></h4>
                                        <div className="btn-wrap">
                                            <a href="https://app.firash.com/register" className="btn-buy">Start Free</a>
                                        </div>
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>No. of Extraction Template</td>
                                    <td>10</td>
                                    <td>Unlimited</td>
                                    <td>Unlimited</td>
                                    <td>Unlimited</td>
                                </tr>
                                <tr>
                                    <td>No. of Document Extraction</td>
                                    <td>50</td>
                                    <td>300</td>
                                    <td>2000</td>
                                    <td>6000</td>
                                </tr>
                                <tr>
                                    <td>Extract Data From:
                                        <br /> - MS Excel documents (xls,xlsx,xlsm,csv)
                                        <br /> - XML (Extensible Markup Language)
                                    </td>
                                    <td><i className="bi bi-check2-circle text-success"></i></td>
                                    <td><i className="bi bi-check2-circle text-success"></i></td>
                                    <td><i className="bi bi-check2-circle text-success"></i></td>
                                    <td><i className="bi bi-check2-circle text-success"></i></td>
                                </tr>
                                <tr>
                                    <td>Fields Extraction</td>
                                    <td><i className="bi bi-check2-circle text-success"></i></td>
                                    <td><i className="bi bi-check2-circle text-success"></i></td>
                                    <td><i className="bi bi-check2-circle text-success"></i></td>
                                    <td><i className="bi bi-check2-circle text-success"></i></td>
                                </tr>
                                <tr>
                                    <td>Tables Extraction</td>
                                    <td><i className="bi bi-check2-circle text-success"></i></td>
                                    <td><i className="bi bi-check2-circle text-success"></i></td>
                                    <td><i className="bi bi-check2-circle text-success"></i></td>
                                    <td><i className="bi bi-check2-circle text-success"></i></td>
                                </tr>
                                <tr>
                                    <td>Download Extracted Data to Excel, CSV and JSON</td>
                                    <td><i className="bi bi-check2-circle text-success"></i></td>
                                    <td><i className="bi bi-check2-circle text-success"></i></td>
                                    <td><i className="bi bi-check2-circle text-success"></i></td>
                                    <td><i className="bi bi-check2-circle text-success"></i></td>
                                </tr>
                                <tr>
                                    <td>Users</td>
                                    <td>Unlimited</td>
                                    <td>Unlimited</td>
                                    <td>Unlimited</td>
                                    <td>Unlimited</td>
                                </tr>
                                <tr>
                                    <td>REST API Integration</td>
                                    <td><i className="bi bi-check2-circle text-success"></i></td>
                                    <td><i className="bi bi-check2-circle text-success"></i></td>
                                    <td><i className="bi bi-check2-circle text-success"></i></td>
                                    <td><i className="bi bi-check2-circle text-success"></i></td>
                                </tr>
                                <tr>
                                    <td>AWS S3 Integration</td>
                                    <td><i className="bi bi-check2-circle text-success"></i></td>
                                    <td><i className="bi bi-check2-circle text-success"></i></td>
                                    <td><i className="bi bi-check2-circle text-success"></i></td>
                                    <td><i className="bi bi-check2-circle text-success"></i></td>
                                </tr>
                                <tr>
                                    <td>No. of AWS Connection</td>
                                    <td>1</td>
                                    <td>Unlimited</td>
                                    <td>Unlimited</td>
                                    <td>Unlimited</td>
                                </tr>
                                <tr>
                                    <td>MySql Integration</td>
                                    <td><i className="bi bi-check2-circle text-success"></i></td>
                                    <td><i className="bi bi-check2-circle text-success"></i></td>
                                    <td><i className="bi bi-check2-circle text-success"></i></td>
                                    <td><i className="bi bi-check2-circle text-success"></i></td>
                                </tr>
                                <tr>
                                    <td>No. of MySql Connection</td>
                                    <td>1</td>
                                    <td>Unlimited</td>
                                    <td>Unlimited</td>
                                    <td>Unlimited</td>
                                </tr>
                                <tr>
                                    <td>Email Notification</td>
                                    <td><i className="bi bi-x-circle text-danger"></i></td>
                                    <td><i className="bi bi-check2-circle text-success"></i></td>
                                    <td><i className="bi bi-check2-circle text-success"></i></td>
                                    <td><i className="bi bi-check2-circle text-success"></i></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Pricing;