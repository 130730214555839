function Howitworks() { 
    return (
        <section id="howitworks" className="about">
            <div className="container">

                <div className="section-title" data-aos="zoom-out">
                    <h2>Process</h2>
                    <p>How It Works</p>
                </div>

                <div className="row content" data-aos="fade-up">
                    <div className="col-lg-12">
                        <p>
                            The cutting-edge technologies were used to develop Fireash, a robust web-based Platform for Data Extraction. Extract specific Excel and XML fields or tables. Send the extracted data to Excel, CSV, JSON, or any other integrations.
                        </p>
                    </div>
                </div>
                <div className="row content" data-aos="fade-up">
                    <h2 style={{"textAlign":"center"}} className="mt-3 mb-3">
                        Automate your document processing with Fireash in just three easy steps!
                    </h2>

                    <div className="col-lg-4 content order-lg-2 order-1 mb-3">
                        <div className="icon-box aos-init aos-animate" data-aos="fade-up" data-aos-delay="100">
                            <div className="icon"><i className="bi bi-gear-wide-connected"></i></div>
                            <h5 className="title"><a href="javascript">Step 1</a></h5>
                            <p className="description">Create extraction and apply rules.</p>
                        </div>
                    </div>
                    <div className="col-lg-4 content order-lg-2 order-1 mb-3">
                        <div className="icon-box aos-init aos-animate" data-aos="fade-up" data-aos-delay="200">
                            <div className="icon"><i className="bi bi-cloud-arrow-up-fill"></i></div>
                            <h5 className="title"><a href="javascript">Step 2</a></h5>
                            <p className="description">Upload your documents using Fireash UI or API.</p>
                        </div>
                    </div>
                    <div className="col-lg-4 content order-lg-2 order-1 mb-1">
                        <div className="icon-box aos-init aos-animate" data-aos="fade-up" data-aos-delay="300">
                            <div className="icon"><i className="bi bi-cloud-arrow-down-fill"></i></div>
                            <h5 className="title"><a href="javascript">Step 3</a></h5>
                            <p className="description">Export extracted data to Excel/CSV/Json.</p>
                        </div>
                    </div>
                </div>

            </div>
        </section>
    )
}

export default Howitworks;