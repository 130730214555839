
import React, { useState, useEffect } from 'react';

const ImageComponent = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const src = isMobile ? 'assets/img/etl_logo_vertical.png' : 'assets/img/etl_logo.png';
  const style = isMobile ? {"marginTop": "10%", "textAlign": "center"} : {}

  return <div className="hero-banner-image" style={style}><img src={src} alt="" /></div>;
};

function Hero() { 
    return (
        <section id="hero" className="d-flex flex-column justify-content-end align-items-center">
            <div className="container">
               
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div className="animate__animated animate__fadeInDown hero-banner-content">
                            <h1>Welcome to Fireash, your ETL solutions partner!</h1>
                            <h4>Unlock the Power of Data with Fireash's Excel and XML ETL Solutions</h4>
                            <p>At Fireash, we understand the unique challenges associated with handling data in Excel & XML formats. Our ETL solutions are tailored specifically to these formats, providing you with powerful tools to extract, transform, and load data seamlessly and efficiently.</p>

                            <div className="btn-box">
                                <a href="https://app.fireash.com" className="btn-get-started animate__animated animate__fadeInUp scrollto">Get started for free</a>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6">
                        <div className="hero-banner-image">
                            {/* <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/DG2e8PWshiQ" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe> */}
                            <ImageComponent className="animate__animated animate__fadeInDown" />
                        </div>
                    </div>
                </div>
            </div>
            
        </section>
    )
}

export default Hero;